
<template>
  <div class="login bg-canvas">
        <AppHeader v-show="$root.isUserLogged"></AppHeader>
        <AppHeaderPublic v-show="!$root.isUserLogged"></AppHeaderPublic>
        <section class="container pb-5 mb-5">
            <div class="row">
                <div class="form-container col-10 mx-auto d-flex flex-column">
                    <FormLogin></FormLogin>
                </div>
            </div>
        </section>
        <AppFooter></AppFooter>
  </div>
</template>



<script>


import AppHeader from "@/components/AppHeader";
import AppHeaderPublic from "@/components/AppHeaderPublic";
import AppFooter from "@/components/AppFooter";
import FormLogin from './widgets/FormLogin.vue';

export default {
    name: 'Login',

    components: {
        AppHeader,
        AppHeaderPublic,
        AppFooter,
        FormLogin
    }, 

    mounted() {
        // go to the default view if the user is already logged in.
        if(this.$store.getters.token && this.$store.getters.user?.ID) {
            this.$router.push({
                path: this.$store.state.global.DEFAULT_VIEW
            })
        }
    }
}
</script>


<style scoped>

.form-container {
    min-height: 85vh;
}
.form-container .form-login {
    width: 450px;
    margin: 100px auto auto;
}
</style>